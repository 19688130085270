export const AuthClientMethods = {
  CLIENT_SECRET_BASIC: 'client_secret_basic',
  CLIENT_SECRET_POST: 'client_secret_post',
  CLIENT_SECRET_JWT: 'client_secret_jwt',
  PRIVATE_KEY_JWT: 'private_key_jwt',
  NONE: 'none',
} as const;

export const AUTH_SIGNING_ALGORITHM_OPTIONS = {
  HS256: 'HS256',
  HS384: 'HS384',
  HS512: 'HS512',
  RS256: 'RS256',
  RS384: 'RS384',
  RS512: 'RS512',
  ES256: 'ES256',
  ES384: 'ES384',
  ES512: 'ES512',
  PS256: 'PS256',
  PS384: 'PS384',
  PS512: 'PS512',
} as const;

export type AuthClientMethod =
  (typeof AuthClientMethods)[keyof typeof AuthClientMethods];

export const AuthClientGrantTypes = {
  AUTHORIZATION_CODE: 'authorization_code',
  REFRESH_TOKEN: 'refresh_token',
  CLIENT_CREDENTIALS: 'client_credentials',
} as const;

export type AuthClientGrantType =
  (typeof AuthClientGrantTypes)[keyof typeof AuthClientGrantTypes];

export const AuthClientScopes = {
  OPEN_ID: 'openid',
  PROFILE: 'profile',
  EMAIL: 'email',
  ADDRESS: 'address',
  PHONE: 'phone',
  SELFIE: 'selfie',
  SELFIE_ATTESTATION: 'selfie_attestation',
  FR_ATTESTATION: 'fr_attestation',
  ID_ATTESTATION: 'id_attestation',
  INCODE_ID: 'incode_id',
  ROLES: 'roles',
  SCORING_RESULTS: 'scoring_results',
  IDENTITY_ASSURANCE: 'identity_assurance',
} as const;

export const AuthClientScopesOrder = {
  [AuthClientScopes.OPEN_ID]: 1,
  [AuthClientScopes.PROFILE]: 2,
  [AuthClientScopes.EMAIL]: 3,
  [AuthClientScopes.ADDRESS]: 4,
  [AuthClientScopes.PHONE]: 5,
  [AuthClientScopes.SELFIE]: 6,
  [AuthClientScopes.SELFIE_ATTESTATION]: 7,
  [AuthClientScopes.FR_ATTESTATION]: 8,
  [AuthClientScopes.ID_ATTESTATION]: 9,
  [AuthClientScopes.INCODE_ID]: 10,
  [AuthClientScopes.ROLES]: 11,
  [AuthClientScopes.SCORING_RESULTS]: 12,
  [AuthClientScopes.IDENTITY_ASSURANCE]: 13,
} as const;

export type AuthClientScope =
  (typeof AuthClientScopes)[keyof typeof AuthClientScopes];

export const AuthClientSettingsKeys = {
  REQUIRE_AUTHORIZATION_CONSENT: 'require-authorization-consent',
  REQUIRE_PROOF_KEY: 'require-proof-key',
  TOKEN_ENDPOINT_AUTH_SIGNING_ALGORITHM:
    'token-endpoint-authentication-signing-algorithm',
  JWK_SET_URL: 'jwk-set-url',
  FORCE_AUTHENTICATION: 'force-authentication',
} as const;

export type AuthClientSettingsKey =
  (typeof AuthClientSettingsKeys)[keyof typeof AuthClientSettingsKeys];

export interface AuthClientConfigResponse {
  registeredClients: AuthClientConfig[];
}

export interface GenerateClientIdResponse {
  clientId: string;
  issuerUrl: string;
  authorizeUrl: string;
  tokenUrl: string;
}

export interface GenerateClientSecretResponse {
  clientSecret: string;
}

export interface AuthClientConfig {
  clientName: string;
  configurationId: string;
  clientId: string;
  flowType: 'workflow' | 'flow';
  clientSecret: string;
  clientAuthenticationMethods: AuthClientMethod[];
  authorizationGrantTypes: AuthClientGrantType[];
  redirectUris: string[];
  postLogoutRedirectUris: string[];
  scopes: AuthClientScope[];
  settings: AuthClientSettings;
  authenticationType: AuthenticationType;
  maxAttempts: number;
  clientOrganization: string;
  signInSignUp: boolean;
  readOnly: boolean;
}

export type AuthClientSettings = {
  [key in AuthClientSettingsKey]: boolean | string;
};

export type TokenSettings = {
  'reuse-refresh-tokens': boolean;
  'access-token-time-to-live': number;
  'refresh-token-time-to-live': number;
  'authorization-code-time-to-live': number;
};

export type ModalConfigData = {
  isOpen: boolean;
  formData: ModalConfigFormData;
  editing: boolean;
};

export type ModalDeleteData = {
  isOpen: boolean;
  clientId: string;
};

export type ModalConfigFormData = {
  clientName: string;
  issuerUrl: string;
  authorizeUrl: string;
  tokenUrl: string;
  clientId: string;
  clientSecret: string;
  flowType: string;
  configurationId: string;
  clientAuthenticationMethods: {
    name: AuthClientMethod;
    value: AuthClientMethod;
    checked: boolean;
  }[];
  authorizationGrantTypes: AuthClientGrantType[];
  redirectUris: { value: string }[];
  postLogoutRedirectUris: { value: string }[] | undefined;
  scopes: AuthClientScope[];
  settings: AuthClientSettings;
  authenticationType: string;
  loginHint: LoginHintType;
  signInSignUp: boolean;
  maxAttempts: number;
  generatedUrl: string;
  oktaIssuerUrl: string;
  oktaApiToken: string;
  tokenSettings: TokenSettings;
  registrationId: string;
  readOnly?: boolean;
  domainReferer: string;
  domainPrefix: string;
};

export const AuthenticationTypes = {
  FACE_AUTH: 'face_auth',
  ONBOARDING: 'onboarding',
  INCODE_ID: 'incode_id',
  USER_CREDENTIALS: 'user_credentials',
  OKTA: 'okta',
} as const;

export type AuthenticationType =
  (typeof AuthenticationTypes)[keyof typeof AuthenticationTypes];

export const FlowCategories = {
  FLOW: 'flow',
  WORKFLOW: 'workflow',
} as const;

export type FlowCategoryType =
  (typeof FlowCategories)[keyof typeof FlowCategories];

export const LoginHint = {
  NONE: 'none',
  UUID: 'uuid',
  PHONE: 'phone',
  EMAIL: 'email',
  NATIONAL_NUMBER: 'nationalNumber',
} as const;

export type LoginHintType = (typeof LoginHint)[keyof typeof LoginHint];

export type GeneratedDataModal = {
  isOpen: boolean;
  info: {
    clientId?: string;
    clientSecret?: string;
    redirectUri?: string;
  };
};
