import { useQuery } from '@tanstack/react-query';

import { client } from '../http/api';

type Organization = {
  alias: string;
  externalId: string;
};

type GetOrganizationDTO = {
  organizations: Organization[];
};

async function getOrganizations() {
  const response = await client.get<GetOrganizationDTO>(`/organization/all`, {
    withCredentials: true,
  });
  return response.data?.organizations;
}

function useGetOrganizations() {
  return useQuery({
    queryKey: ['Organizations'],
    queryFn: getOrganizations,
    retry: 1,
  });
}

export { useGetOrganizations };
export default useGetOrganizations;
