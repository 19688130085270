import React, { useMemo, useEffect } from 'react';
import {
  ModalDialog,
  Button,
  Select,
  Input,
  Checkbox,
  NotificationBox,
} from '@incodetech/ui';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidHttpUrl, sortAlphabetically } from '../../../utils/utils';
import {
  AuthClientGrantTypes,
  AuthClientMethods,
  AuthClientScopes,
  ModalConfigFormData,
  AuthClientGrantType,
  AuthClientScope,
  AuthenticationTypes,
  FlowCategories,
  LoginHint,
  AUTH_SIGNING_ALGORITHM_OPTIONS,
} from '../../../types/authClientConfig';
import useFlowsInfo from '../../../hooks/useFlowsInfo';
import useWorkflowsInfo from '../../../hooks/useWorkflowsInfo';
import AuthConfigurationMultipleChecks from './AuthConfigurationMultipleChecks';
import AuthConfigurationInput from './AuthConfigurationInput';

import './AuthConfigurationModal.scss';
import { ClientRegistrationData } from '../../../types/clientRegistration';
interface AddAuthConfigurationModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  handleFormSubmit: SubmitHandler<ModalConfigFormData>;
  formData: ModalConfigFormData;
  editing: boolean;
  serverError: string;
  onRegenerateSecret: (watchedClientId: string) => void;
  isRegeneratingSecret: boolean;
  clientRegistrations: ClientRegistrationData[];
}

const defaulFormValue: ModalConfigFormData = {
  clientName: '',
  issuerUrl: '',
  authorizeUrl: '',
  tokenUrl: '',
  configurationId: '',
  flowType: null,
  clientId: '',
  clientSecret: '',
  authenticationType: '',
  loginHint: 'none',
  clientAuthenticationMethods: Object.values(AuthClientMethods)?.map(a => ({
    name: a,
    value: a,
    checked: false,
  })),
  authorizationGrantTypes: [],
  redirectUris: [{ value: '' }],
  postLogoutRedirectUris: [{ value: '' }],
  scopes: [],
  settings: {
    'require-authorization-consent': false,
    'require-proof-key': false,
    'jwk-set-url': '',
    'token-endpoint-authentication-signing-algorithm': '',
    'force-authentication': '',
  },
  tokenSettings: {
    'reuse-refresh-tokens': true,
    'access-token-time-to-live': 5,
    'refresh-token-time-to-live': 60,
    'authorization-code-time-to-live': 5,
  },
  signInSignUp: false,
  maxAttempts: 1,
  generatedUrl: '',
  oktaIssuerUrl: '',
  oktaApiToken: '',
  registrationId: '',
  domainReferer: '',
  domainPrefix: '',
};

const AuthConfigurationModal: React.FC<AddAuthConfigurationModalProps> = ({
  isOpen,
  onRequestClose,
  handleFormSubmit,
  formData,
  editing,
  serverError,
  onRegenerateSecret,
  isRegeneratingSecret,
  clientRegistrations,
}) => {
  const { t } = useTranslation();
  const isReadOnly = formData.readOnly;
  const methods = useForm<ModalConfigFormData>({
    values: {
      ...defaulFormValue,
      registrationId: clientRegistrations
        ? clientRegistrations[0]?.registrationId
        : '',
      ...(editing &&
        formData &&
        Object.keys(formData) && {
          ...formData,
          domainReferer: formData.domainReferer ?? '',
          tokenSettings: {
            ...(formData.tokenSettings && {
              ...formData?.tokenSettings,
              'access-token-time-to-live':
                formData?.tokenSettings[
                  'settings.token.access-token-time-to-live'
                ] / 60,
              'refresh-token-time-to-live':
                formData?.tokenSettings[
                  'settings.token.refresh-token-time-to-live'
                ] / 60,
              'authorization-code-time-to-live':
                formData?.tokenSettings[
                  'settings.token.authorization-code-time-to-live'
                ] / 60,
            }),
          },
        }),
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = methods;

  const { data: flowsInfo } = useFlowsInfo();
  const { data: workflowsInfo } = useWorkflowsInfo();
  const watchedClientId = watch('clientId');
  const watchedFlowType = watch('flowType');
  const watchedAuthType = watch('authenticationType');
  const watchedSignInSignUp = watch('signInSignUp');
  const watcheOktaIssuerUrl = watch('oktaIssuerUrl');
  const watchedOktaApiToken = watch('oktaApiToken');

  const shouldShowFlowType =
    watchedAuthType === AuthenticationTypes.ONBOARDING ||
    (watchedAuthType === AuthenticationTypes.FACE_AUTH && watchedSignInSignUp);

  const configurations = useMemo(() => {
    return watchedFlowType === FlowCategories.FLOW
      ? (
          flowsInfo?.flows
            // ?.filter(flow => flow.active)
            ?.sort(sortAlphabetically) ?? []
        ).map(flow => {
          return {
            value: flow.id,
            label: flow.name,
          };
        })
      : workflowsInfo?.workflows?.map(workflow => {
          return {
            value: workflow.id,
            label: workflow.name,
          };
        }) ?? [];
  }, [flowsInfo, watchedFlowType, workflowsInfo]);

  const handleChangeGrantTypes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (checked) {
      setValue('authorizationGrantTypes', [
        ...watch('authorizationGrantTypes'),
        name as AuthClientGrantType,
      ]);
    } else {
      setValue(
        'authorizationGrantTypes',
        watch('authorizationGrantTypes')?.filter(item => item !== name),
      );
    }
  };

  const handleChangeScopes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (checked) {
      setValue('scopes', [...watch('scopes'), name as AuthClientScope]);
    } else {
      setValue(
        'scopes',
        watch('scopes')?.filter(item => item !== name),
      );
    }
  };

  const afterClose = () => {
    reset();
  };

  useEffect(() => {
    if (!watcheOktaIssuerUrl) {
      clearErrors('oktaApiToken');
    }

    if (!watchedOktaApiToken) {
      clearErrors('oktaIssuerUrl');
    }
  }, [watcheOktaIssuerUrl, watchedOktaApiToken, clearErrors]);

  return (
    <ModalDialog
      isOpen={isOpen}
      closeModal={onRequestClose}
      afterModalClose={afterClose}
      shouldCloseOnOverlayClick={false}
      modalTitle={t('authConfiguration.form.title')}
      classes="auth-configuration-modal"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(data => {
            handleFormSubmit(data);
            clearErrors();
          })}
          className="configuration-form"
        >
          {editing ? (
            <>
              <div className="input-wrapper">
                <div className="client-secret">
                  <Input
                    id="clientId"
                    label={t('authConfiguration.form.clientId')}
                    {...register('clientId', {
                      required: t('authConfiguration.form.requiredField'),
                    })}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="client-secret">
                  <Input
                    id="clientSecret"
                    label={t('authConfiguration.form.clientSecret')}
                    disabled={true}
                    value="********************************"
                  />
                  <Button
                    size="sm"
                    label={t('authConfiguration.notifications.regenerate')}
                    variant="faded"
                    disabled={isReadOnly || isRegeneratingSecret}
                    onClick={() => onRegenerateSecret(watchedClientId)}
                  />
                </div>
              </div>
            </>
          ) : null}
          <div className="auth-configuration-content">
            <Input
              id="name"
              label={t('authConfiguration.form.name')}
              variant="full"
              {...register('clientName', {
                required: t('authConfiguration.form.requiredField'),
              })}
              error={errors.clientName?.message}
              disabled={isReadOnly}
            />
            <div className="input-wrapper">
              <Select
                id="authenticationType"
                {...register('authenticationType', {
                  required: t('authConfiguration.form.requiredField'),
                })}
                variant="default"
                label={t('authConfiguration.form.authTypeLabel')}
                placeholder={t('authConfiguration.form.authTypePlaceholder')}
                error={errors?.authenticationType?.message}
                options={Object.values(AuthenticationTypes).map(type => ({
                  value: type,
                  label: type,
                }))}
                disabled={isReadOnly}
              />
            </div>
            {watchedAuthType === AuthenticationTypes.OKTA && (
              <>
                <div className="input-wrapper">
                  <Select
                    id="registrationId"
                    {...register('registrationId', {
                      required: t('authConfiguration.form.requiredField'),
                    })}
                    variant="default"
                    label={t('authConfiguration.form.registrationId')}
                    error={errors?.registrationId?.message}
                    options={
                      clientRegistrations
                        ? clientRegistrations?.map(registration => ({
                            value: registration?.registrationId,
                            label: registration?.clientName,
                          }))
                        : []
                    }
                    disabled={isReadOnly}
                  />
                </div>
                <div className="input-wrapper">
                  <Input
                    id="domainReferer"
                    label={t('authConfiguration.form.domainReferer')}
                    variant="full"
                    {...register('domainReferer', {
                      validate: value =>
                        isValidHttpUrl(value)
                          ? true
                          : `${t('clientRegistration.form.validUrlError')}`,
                    })}
                    error={errors.domainReferer?.message}
                    disabled={isReadOnly}
                  />
                </div>
                <div className="input-wrapper">
                  <Input
                    id="domainPrefix"
                    label={t('authConfiguration.form.domainPrefix')}
                    variant="full"
                    {...register('domainPrefix')}
                    disabled={isReadOnly}
                  />
                </div>
              </>
            )}

            {watchedAuthType === AuthenticationTypes.FACE_AUTH && (
              <>
                <div className="input-wrapper">
                  <Select
                    id="loginHint"
                    {...register('loginHint', {
                      required: t('authConfiguration.form.requiredField'),
                    })}
                    variant="default"
                    label={t('authConfiguration.form.loginHintLabel')}
                    error={errors?.loginHint?.message}
                    options={Object.values(LoginHint).map(type => ({
                      value: type,
                      label: type,
                    }))}
                    disabled={isReadOnly}
                  />
                </div>
                <div className="input-wrapper">
                  <Checkbox
                    id="signInSignUp"
                    label={t('authConfiguration.form.signInSignUpLabel')}
                    {...register('signInSignUp')}
                    classes="stats-item"
                    disabled={isReadOnly}
                  />
                </div>
                <div className="input-wrapper">
                  <Input
                    id="maxAttempts"
                    label={t('authConfiguration.form.maxAttemptsLabel')}
                    variant="full"
                    type="number"
                    {...register('maxAttempts', {
                      required: t('authConfiguration.form.requiredField'),
                      min: 1,
                      max: 10,
                    })}
                    error={
                      errors?.maxAttempts?.type === 'min' ||
                      errors?.maxAttempts?.type === 'max'
                        ? t('authConfiguration.form.minMaxValue')
                        : ''
                    }
                    disabled={isReadOnly}
                  />
                </div>
              </>
            )}
            {shouldShowFlowType && (
              <div className="input-wrapper">
                <Select
                  id="flowType"
                  {...register('flowType', {
                    required: t('authConfiguration.form.requiredField'),
                  })}
                  variant="default"
                  error={errors?.flowType?.message}
                  placeholder={t('authConfiguration.form.selectFlowType')}
                  label={t('authConfiguration.form.flowType')}
                  options={Object.values(FlowCategories).map(flow => ({
                    value: flow,
                    label: t(`authConfiguration.flowTypeLabels.${flow}`),
                  }))}
                  disabled={isReadOnly}
                />
              </div>
            )}

            {shouldShowFlowType && watchedFlowType && (
              <div className="input-wrapper">
                <Select
                  id="configurationId"
                  {...register('configurationId', {
                    required: t('authConfiguration.form.requiredField'),
                  })}
                  variant="default"
                  error={errors?.configurationId?.message}
                  placeholder={t(
                    watchedFlowType === FlowCategories.FLOW
                      ? 'authConfiguration.form.selectFlow'
                      : 'authConfiguration.form.selectWorkflow',
                  )}
                  label={t(
                    watchedFlowType === FlowCategories.FLOW
                      ? 'authConfiguration.form.flowId'
                      : 'authConfiguration.form.workflowId',
                  )}
                  options={configurations}
                  disabled={isReadOnly}
                />
              </div>
            )}

            {watchedAuthType === AuthenticationTypes.ONBOARDING ? (
              <>
                <Input
                  id="oktaIssuerUrl"
                  label={t('authConfiguration.form.oktaIssuerUrl')}
                  variant="full"
                  {...register('oktaIssuerUrl', {
                    required: Boolean(watchedOktaApiToken)
                      ? t('authConfiguration.form.requiredField')
                      : undefined,
                    validate: value =>
                      isValidHttpUrl(value)
                        ? true
                        : `${t('clientRegistration.form.validUrlError')}`,
                  })}
                  error={errors?.oktaIssuerUrl?.message}
                  disabled={isReadOnly}
                />
                <Input
                  id="oktaApiToken"
                  label={t('authConfiguration.form.oktaApiToken')}
                  variant="full"
                  {...register('oktaApiToken', {
                    required: Boolean(watcheOktaIssuerUrl)
                      ? t('authConfiguration.form.requiredField')
                      : undefined,
                  })}
                  error={errors?.oktaApiToken?.message}
                  disabled={isReadOnly}
                />
              </>
            ) : null}

            <AuthConfigurationInput
              name="redirectUris"
              label={t('authConfiguration.form.redirectUris')}
              errorMessage={t('authConfiguration.form.requiredField')}
              isUrl
              required
              disabled={isReadOnly}
            />
            <AuthConfigurationInput
              name="postLogoutRedirectUris"
              label={t('authConfiguration.form.postLogoutRedirectUris')}
              errorMessage={t('authConfiguration.form.requiredField')}
              disabled={isReadOnly}
              isUrl
            />
            <div className="input-wrapper">
              <label className="input-label">
                {t('authConfiguration.form.clientAuthenticationMethods')}
              </label>
              <AuthConfigurationMultipleChecks
                name="clientAuthenticationMethods"
                required
                errorMessage={t(
                  'authConfiguration.form.clientAuthenticationMethodsError',
                )}
                disabled={isReadOnly}
              />
            </div>
            <div className="input-wrapper">
              <label className="input-label">
                {t('authConfiguration.form.authorizationGrantTypes')}
              </label>
              {Object.values(AuthClientGrantTypes)?.map(item => (
                <div className="input-group" key={item}>
                  <Checkbox
                    label={item}
                    id={item}
                    name={item}
                    checked={
                      watch('authorizationGrantTypes')?.includes(
                        item as AuthClientGrantType,
                      ) || item === AuthClientGrantTypes.AUTHORIZATION_CODE
                    }
                    disabled={
                      isReadOnly ||
                      item === AuthClientGrantTypes.AUTHORIZATION_CODE
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeGrantTypes(e)
                    }
                  />
                </div>
              ))}
            </div>
            <div className="input-wrapper">
              <label className="input-label">
                {t('authConfiguration.form.scopes')}
              </label>
              {Object.values(AuthClientScopes)?.map(item => (
                <div className="input-group" key={item}>
                  <Checkbox
                    label={item}
                    id={item}
                    name={item}
                    checked={
                      watch('scopes')?.includes(item as AuthClientScope) ||
                      item === AuthClientScopes.OPEN_ID
                    }
                    disabled={isReadOnly || item === AuthClientScopes.OPEN_ID}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeScopes(e);
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="input-wrapper">
              <label className="input-label">
                {t('authConfiguration.form.settings')}
              </label>
              <div className="input-group">
                <Checkbox
                  label={t(
                    'authConfiguration.form.require-authorization-consent',
                  )}
                  id={'settings.require-authorization-consent'}
                  {...register('settings.require-authorization-consent')}
                  disabled={isReadOnly}
                />
              </div>
              <div className="input-group">
                <Checkbox
                  label={t('authConfiguration.form.require-proof-key')}
                  id={'settings.require-proof-key'}
                  {...register('settings.require-proof-key')}
                  disabled={isReadOnly}
                />
              </div>
              <div className="input-group">
                <Checkbox
                  label={t('authConfiguration.form.reuse-refresh-tokens')}
                  id={'tokenSettings.reuse-refresh-tokens'}
                  {...register('tokenSettings.reuse-refresh-tokens')}
                  disabled={isReadOnly}
                />
              </div>
              <div className="input-group">
                <Checkbox
                  label={t('authConfiguration.form.force-authentication')}
                  id={'settings.force-authentication'}
                  {...register('settings.force-authentication')}
                  disabled={isReadOnly}
                />
              </div>
              <div className="input-group">
                <Input
                  type="number"
                  label={t('authConfiguration.form.access-token-time-to-live')}
                  id={'tokenSettings.access-token-time-to-live'}
                  {...register('tokenSettings.access-token-time-to-live', {
                    min: {
                      value: 0.1,
                      message: t('authConfiguration.form.tokenError'),
                    },
                  })}
                  error={
                    errors.tokenSettings?.['access-token-time-to-live']?.message
                  }
                  disabled={isReadOnly}
                />
              </div>
              <div className="input-group">
                <Input
                  type="number"
                  label={t('authConfiguration.form.refresh-token-time-to-live')}
                  id={'tokenSettings.refresh-token-time-to-live'}
                  {...register('tokenSettings.refresh-token-time-to-live', {
                    min: {
                      value: 0.1,
                      message: t('authConfiguration.form.tokenError'),
                    },
                  })}
                  error={
                    errors.tokenSettings?.['refresh-token-time-to-live']
                      ?.message
                  }
                  disabled={isReadOnly}
                />
              </div>
              <div className="input-group">
                <Input
                  type="number"
                  label={t(
                    'authConfiguration.form.authorization-code-time-to-live',
                  )}
                  id={'tokenSettings.authorization-code-time-to-live'}
                  {...register(
                    'tokenSettings.authorization-code-time-to-live',
                    {
                      min: {
                        value: 0.1,
                        message: t('authConfiguration.form.tokenError'),
                      },
                    },
                  )}
                  error={
                    errors.tokenSettings?.['authorization-code-time-to-live']
                      ?.message
                  }
                  disabled={isReadOnly}
                />
              </div>
              <div className="input-config">
                <Select
                  id={
                    'settings.token-endpoint-authentication-signing-algorithm'
                  }
                  {...register(
                    'settings.token-endpoint-authentication-signing-algorithm',
                  )}
                  placeholder="Select an option"
                  variant="default"
                  label={t(
                    `authConfiguration.form.token-endpoint-authentication-signing-algorithm`,
                  )}
                  options={Object.values(AUTH_SIGNING_ALGORITHM_OPTIONS).map(
                    j => ({
                      value: j,
                      label: j,
                    }),
                  )}
                  disabled={isReadOnly}
                />
              </div>
              <div className="input-config">
                <Input
                  type="text"
                  id={'settings.jwk-set-url'}
                  label={t(`authConfiguration.form.jwk-set-url`)}
                  disabled={isReadOnly}
                  {...register('settings.jwk-set-url', {
                    validate: value =>
                      isValidHttpUrl(value as string)
                        ? true
                        : `${t('clientRegistration.form.validUrlError')}`,
                  })}
                  error={errors?.settings?.['jwk-set-url']?.message}
                  variant="full"
                />
              </div>
            </div>
          </div>
          {serverError && (
            <NotificationBox variant="error" text={serverError} />
          )}

          <div className="separator" />
          <div className="button-container">
            <Button
              label={t('authConfiguration.form.buttonSave')}
              type="submit"
              variant="blue"
              classes="modal-button"
              disabled={isReadOnly}
            />
          </div>
        </form>
      </FormProvider>
    </ModalDialog>
  );
};

export default AuthConfigurationModal;
