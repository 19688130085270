import { useQuery } from '@tanstack/react-query';

import { client } from '../http/api';
import { FlowsBasicInfo } from '../types/flows';

interface FlowsInfoResponse {
  flows: FlowsBasicInfo[];
}

async function getFlows() {
  const response = await client.get<FlowsInfoResponse>(`/auth/domain/flows`, {
    withCredentials: true,
  });
  return response.data;
}

function useFlowsInfo() {
  return useQuery({
    queryKey: ['Flows'],
    queryFn: () => getFlows(),
    retry: 1,
  });
}

export { useFlowsInfo };
export default useFlowsInfo;
