/**
 * Retrieves the value of an environment variable.
 *
 * Priority:
 * 1. Dynamically injected variables from `window.env` (set by the backend at runtime).
 * 2. Statically defined variables from `process.env` (from a `.env` file during build time).
 * 3. Fallback to a provided default value if the variable is not found.
 *
 * @param key - The name of the environment variable.
 * @param defaultValue - The default value to return if the variable is not found (defaults to an empty string).
 * @returns The resolved environment variable value or the default value.
 */
export const getEnv = (key: string, defaultValue: string = ''): string => {
    return (
        (window as any).env?.[key] ||
        process.env[key] ||
        defaultValue
    );
};
