import axios from 'axios';

import { ORG_ID, AUTHORIZED, CLIENT_ID } from '../constants/constants';

const CLIENT_ID_BLACKLIST_PATHS = ['auth/client/basic-info/all'];
const ORG_ID_BLACKLIST_PATHS = [
  'auth/client/basic-info/all',
  '/organization/all',
];

const client = axios.create({
  baseURL: '/',
});

client.interceptors.request.use(
  config => {
    const clientId = localStorage.getItem(CLIENT_ID);
    const organizationId = localStorage.getItem(ORG_ID);
    const url = new URL(config.url, window.location.origin);
    const shouldIncludeClientId = !CLIENT_ID_BLACKLIST_PATHS.includes(
      url.pathname,
    );
    const shouldIncludeOrgId = !ORG_ID_BLACKLIST_PATHS.includes(url.pathname);

    const searchParams = new URLSearchParams(url.search);
    if (clientId && shouldIncludeClientId) {
      searchParams.set('client_id', clientId);
    }
    if (organizationId && shouldIncludeOrgId) {
      searchParams.set('organization_id', organizationId);
    }
    config.url = `${url.pathname}?${searchParams.toString()}`;

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  response => response,
  error => {
    const status = error.response ? error.response.status : null;

    if (status === 401 || status === 403) {
      localStorage.removeItem(AUTHORIZED);
      localStorage.removeItem(ORG_ID);
      localStorage.removeItem(CLIENT_ID);
      window.location.href = '/log-in';
    }

    return Promise.reject(error);
  },
);

export { client };
