import { useQuery } from '@tanstack/react-query';

import { client } from '../http/api';
import { WorkflowBasicInfo } from '../types/workflow';

interface WorkflowsInfoResponse {
  workflows: WorkflowBasicInfo[];
}

async function getWorkflows() {
  const response = await client.get<WorkflowsInfoResponse>(
    `/auth/domain/workflows`,
    {
      withCredentials: true,
    },
  );
  return response.data;
}

function useWorkflowsInfo() {
  return useQuery({
    queryKey: ['Workflows'],
    queryFn: () => getWorkflows(),
    retry: 1,
  });
}

export { useWorkflowsInfo };
export default useWorkflowsInfo;
