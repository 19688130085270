import { useMutation } from '@tanstack/react-query';

import { client } from '../http/api';
import { Client } from '../types/client';

async function getClients({ email }: { email: string }) {
  const response = await client.get<{ clients: Client[] }>(
    `/auth/client/basic-info/all?email=${email}`,
  );
  console.error(response.data);
  return response.data.clients;
}

function useOrgClients() {
  return useMutation({
    mutationFn: getClients,
  });
}

export { useOrgClients };
export default useOrgClients;
